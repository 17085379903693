<template>
  <div>
    <div class="Login base-height">
      <div class="login_left"></div>
      <div class="mainBox">
        <h1>
          <router-link to="/">
            <img src="@/assets/images/reviewmind/logo2.png" alt="로고" />
          </router-link>
        </h1>
        <h2>{{ $t("findPwd") }}</h2>

        <div class="form login">
          <fieldset>
            <legend>로그인</legend>
            <div class="cover bg">
              <input v-model="email" type="text" :placeholder="$t('email')" />
              <!-- <em v-show="errMsg" class="errMsg">입력한 정보가 없거나 일치하지 않습니다.</em> -->
            </div>
            <div class="cover bg">
              <div class="phone">
                <input
                  v-model="mobileNo"
                  type="text"
                  :placeholder="$t('findId_mobile')"
                />
                <button @click="requestCertNumber" id="phone_btn">
                  {{ $t("send") }}
                </button>
              </div>
              <div v-show="veyfyCodeVisible" class="cover bg">
                <div class="phone">
                  <input
                    type="text"
                    v-model="certNumber"
                    :placeholder="$t('certNumber')"
                  />
                  <button @click="verifyNumber" id="phone_btn">
                    {{ $t("confirm") }}
                  </button>
                </div>
              </div>
              <!-- <em v-show="errMsg" class="errMsg">입력한 정보가 없거나 일치하지 않습니다.</em> -->
            </div>
            <div class="cover bg">
              <button
                type="button"
                class="btn findEmail primary"
                @click="submit"
              >
                {{ $t("findPwd") }}
              </button>
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import sha256 from "js-sha256";
import { findPassword } from "@/api/member";
import { mapState } from "vuex";
import { format } from "@/mixins/format";
import { verifyMobile, createCertNumber } from "@/api/index";

export default {
  mixins: [format],
  data() {
    return {
      email: "",
      errMsg: false,
      mobileNo: "",
      certNumber: "",
      verify: false,
      veyfyCodeVisible: false,
    };
  },
  mounted() {
    this.setNavbar(false);
    this.setFooter(false);
  },
  computed: {
    ...mapState(["isLogin"]),
  },
  methods: {
    requestCertNumber() {
      if (this.mobileNo == "") {
        return alert("번호를 입력해주세요.");
      }
      let data = {
        mobileNo: this.mobileNo,
      };
      this.veyfyCodeVisible = true;
      createCertNumber(data).then((res) => {
        if (res.status == 200) {
          this.verify = true;
          alert(res.data.message);
        } else {
          this.verify = false;
          alert(res.data.message);
        }
      });
    },
    verifyNumber() {
      let data = {
        mobileNo: this.mobileNo,
        certNumber: this.certNumber,
      };
      verifyMobile(data).then((res) => {
        if (res.data.status == 200) {
          this.verify = true;
          alert(res.data.message);
        } else {
          this.verify = false;
          alert(res.data.message);
        }
      });
    },

    submit() {
      if (this.email == "") {
        return alert("이메일을 입력해 주세요.");
      } else if (this.emailValidate(this.email) == false) {
        return alert("이메일 형식이 맞지 않습니다.");
      } else if (this.mobileNo == "") {
        return alert("번호를 입력해 주세요.");
      } else if (this.verify == false || this.certNumber == "") {
        return alert("휴대폰 인증을 해주세요.");
      }

      let data = {
        email: this.email,
        mobileNo: this.mobileNo,
      };
      findPassword(data).then((res) => {
        if (res.status === 200) {
          this.$router.push({
            name: "chgPwd",
            params: {
              email: res.data.data.email,
              mobileNo: res.data.data.mobileNo,
            },
          });
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
  },
  beforeDestroy() {
    this.setNavbar(true);
    this.setFooter(true);
  },
};
</script>
